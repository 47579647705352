import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGES } from "../../constant/assests";
import { listApi } from "../../api/commonApi";
import { APIS } from "../../api/endpoints";

const NavBar = ({ buttonData, showBack, fromContestLeaderBoard }) => {
  const { id } = useParams(); ///for LeaderBoarder and Contest Page for partner Image Get ID from Url
  const urlParams = new URLSearchParams(window.location.search);
  const pid = urlParams.get("pid"); ///for Contest LeaderBoarder Page for partner Image Get ID from Url as pid
  const [partnerProfile, setPartnerProfile] = useState();
  const [loadingImage, setLoadingImage] = useState(false);
  const navigate = useNavigate();

  /*API Call For Partner Data  */

  useEffect(() => {
    setLoadingImage(true);
    listApi(APIS.LIST_LEADERBOARD, {
      partnerId: fromContestLeaderBoard ? pid : id,
    }).then((result) => {
      setPartnerProfile(result?.partnerData?.profileImage);
      setLoadingImage(false);
    });
  }, []);

  /*Return */

  return (
    // <nav class=" flex items-center px-1 bg-white shadow-md ">
    //   <img
    //     src={IMAGES.FINGER_LOGO}
    //     class="h-10 sm:h-11 md:h-11 lg:h-11 xl:h-11 2xl:h-15"
    //   />
    //   <div class=" mx-auto max-w-7xl px-2 sm:px-6 lg:px-1">
    //     <div class="relative flex h-16 items-center justify-between">
    //       <div class="flex flex-1 items-center justify-center sm:items-stretch   ">
    //         {!!showBack && (
    //           <button
    //             class={` flex px-3 py-2 rounded-md text-sm items-center	font-medium text-sm sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl	`}
    //             onClick={() => navigate(-1)}
    //           >
    //             <img
    //               src={IMAGES.BACK_ARROW}
    //               class="h-5 sm:h-7 md:h-7 lg:h-7 xl:h-7 2xl:h-10"
    //             />
    //             Back
    //           </button>
    //         )}

    //         <div class=" sm:ml-6 sm:block">
    //           <div class="flex space-x-4 ">
    //             {buttonData?.map((button, index, arr) => {
    //               return (
    //                 <button
    //                   class={`${
    //                     button?.active ? "text-[#0369a1]" : "text-gray-300"
    //                   } hover:text-[#0369a1]  px-3 py-2 rounded-md text-sm sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl	font-bold	`}
    //                   onClick={() => navigate(`/${button?.navigate}/${id}`)}
    //                 >
    //                   {button?.label}
    //                 </button>
    //               );
    //             })}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   {loadingImage ? (
    //     <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full "></div>
    //   ) : (
    //     <img
    //       src={partnerProfile}
    //       class="h-10 sm:h-11 md:h-11 lg:h-11 xl:h-11 2xl:h-15"
    //     />
    //   )}
    // </nav>

    <nav className="flex items-center justify-between px-2 py-3 bg-white shadow-md">
      <img
        src={IMAGES.FINGER_LOGO}
        className="h-8 w-auto sm:h-10"
        alt="Finger Logo"
      />
      <div class=" mx-auto max-w-7xl px-2 sm:px-6 lg:px-1">
        <div class="relative flex h-16 items-center justify-between">
          <div class="flex flex-1 items-center justify-center sm:items-stretch   ">
            {!!showBack && (
              <button
                class={` flex px-3 py-2 rounded-md text-sm items-center	font-medium text-sm sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl	`}
                onClick={() => navigate(-1)}
              >
                <img
                  src={IMAGES.BACK_ARROW}
                  class="h-5 sm:h-7 md:h-7 lg:h-7 xl:h-7 2xl:h-10"
                />
                Back
              </button>
            )}

            <div class=" sm:ml-6 sm:block">
              <div class="flex space-x-4 ">
                {buttonData?.map((button, index, arr) => {
                  return (
                    <button
                      class={`${button?.active ? "text-[#0369a1]" : "text-gray-300"
                        } hover:text-[#0369a1]  px-3 py-2 rounded-md text-sm sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl	font-bold	`}
                      onClick={() => navigate(`/${button?.navigate}/${id}`)}
                    >
                      {button?.label}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {loadingImage ? (
        <div className="relative inline-flex items-center justify-center w-8 h-8 sm:w-10 sm:h-10 overflow-hidden bg-gray-100 rounded-full"></div>
      ) : (
        <img
          src={partnerProfile}
          className="h-8 w-8 sm:h-10 sm:w-10 rounded-full object-cover"
          alt="Partner Profile"
        />
      )}
    </nav>


  );
};

export default NavBar;
